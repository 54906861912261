@import './src/scss/variables';

body.dark-theme {
	.mine__cell {
		color: $dark-color;
	}

  .mine__cell_closed {
    background-color: $dark-color6;
  }

	.mine__cell_opened {
		background: $dark-color2;
	}

	.mine__fire.mine__blowup {
		color: $dark-color;
	}

	.mine .miss {
		background: $dark-color2;
	}

	.mine__table-content {
		background: $dark-color6;
	}
}